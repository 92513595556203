import React from 'react';
import { Widgets } from '../widgets';
import { Main } from '../parts/main';
import { graphql } from 'gatsby';
import { PageSlice } from '../../../gatsby-node';
import { PageQueryQuery } from '../../../types/gatsby-types';
import { useLayoutTheme } from '../layout/layout';
import { Seo } from '../Seo/seo';
import { getThemeOrDefault } from '../../helpers/theme-helpers';

/**
 * Come from Gatsby, see gatsby-node.js
 */
export interface PageTemplateProps {
  data: PageQueryQuery;
  pageContext: PageSlice;
}

export const PageFragment = graphql`
  fragment PageFragment on Nmbl_Page {
    contentItemId
    alias {
      alias
    }
    headerTheme {
      theme
    }
    footerTheme {
      theme
    }
    seo {
      ...SeoFragment
    }
    displayText
    bag {
      contentItems {
        ...WidgetFragment
      }
    }
  }
`;

const PageTemplate: React.FC<PageTemplateProps> = ({ data, pageContext }) => {
  const page = data.nmbl.page && data.nmbl.page[0];
  if (!page) {
    throw new Error('Error loading page.');
  }

  useLayoutTheme(
    getThemeOrDefault(page.headerTheme?.theme, 'dark'),
    page.footerTheme?.theme ?? 'dark'
  );

  return (
    <Main template="page">
      <Seo
        {...page.seo}
        title={page.seo?.title ?? page.displayText}
        path={pageContext.pagePath}
      />
      <Widgets widgets={page.bag?.contentItems} />
    </Main>
  );
};
export default PageTemplate;

export const pageQuery = graphql`
  query PageQuery($slug: String!) {
    nmbl {
      page(where: { alias: { alias: $slug } }) {
        ...PageFragment
      }
    }
  }
`;
